import React from 'react'
import { withTheme } from 'emotion-theming'
import HeroStyles from './Hero.styles'
import ItemBankStyles from './ItemBank.styles'
import HelperStyles from './Helper.styles'

const GlobalStyles = withTheme(() => (
  <>
    <HeroStyles />
    <ItemBankStyles />
    <HelperStyles />
  </>
))

export default GlobalStyles
