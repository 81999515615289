import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const HelperStyles = withTheme(() => (
  <Global
    styles={css`
      .card-icon {
        max-width: 100px !important; // important needed to override gatsby wrapper
        margin-left: auto;
        margin-right: auto;
      }

      .center-children {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    `}
  />
))

export default HelperStyles
